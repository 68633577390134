
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import Pagination from "@/comp/Pagination/index.vue";
import { getAppBaseApi, getHeaders } from "@/api/request";
import { setUnion, unionList } from "@/api/request/player";

//组件
@Component({
  name: "UnionList",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private avatarList: string[] = [];
  private listLoading: boolean = true;

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await unionList({});

    //数据赋值
    this.list = data.result;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.addForm = {
      //临时数据
      leader_id_str: "", //负责人ID

      //基本信息
      name: "", //公会名称
      phone: "", //手机号
      email: "", //邮箱地址
      leader_id: 0, //负责人ID
      company_ssm: "", //公司ssm
      leader_name: "", //负责人昵称
      company_addr: "", //公司地址
      leader_credential: "", //负责人证件
    };
    this.ssmFileList = [];
    this.creFileList = [];
    this.dialogStatus = "create";

    //显示对话框
    this.dialogVisible = true;
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.dialogStatus = "update";
    this.addForm = GFunc.deepClone(row);
    this.ssmFileList = [{ url: row.company_ssm }];
    this.creFileList = [{ url: row.leader_credential }];
    this.addForm.leader_id_str = this.addForm.leader_id.toString();

    //显示对话框
    this.dialogVisible = true;
  }

  //头像预览
  private openAvatar(src: string): void {
    //数据赋值
    this.avatarList = [];
    this.avatarList.push(src);
  }

  //----------------------------------------- 添加/编辑 -----------------------------------------
  //定义变量
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;
  private titleMap: any = { update: "编辑公会", create: "新增公会" };

  //添加表单
  private addForm: any = {
    //临时数据
    leader_id_str: "", //负责人ID

    //基本信息
    name: "", //公会名称
    phone: "", //手机号
    email: "", //邮箱地址
    leader_id: 0, //负责人ID
    company_ssm: "", //公司ssm
    leader_name: "", //负责人昵称
    company_addr: "", //公司地址
    leader_credential: "", //负责人证件
  };

  //处理关闭
  private handleClose(): void {
    //隐藏界面
    this.dialogVisible = false;
  }

  //处理创建
  private async createData() {
    //判断处理
    if (this.addForm.name == "") return Message({ message: "请输入公会名称！", type: "error", duration: 5 * 1000 });
    if (this.addForm.leader_name == "") return Message({ message: "请输入负责人昵称！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.leader_id_str) == 0) return Message({ message: "请输入负责人ID！", type: "error", duration: 5 * 1000 });
    if (this.addForm.leader_credential == "") return Message({ message: "请添加负责人证件", type: "error", duration: 5 * 1000 });
    if (this.addForm.company_ssm == "") return Message({ message: "请添加公司ssm", type: "error", duration: 5 * 1000 });
    if (this.addForm.phone == "") return Message({ message: "请输入手机号", type: "error", duration: 5 * 1000 });
    if (this.addForm.email == "") return Message({ message: "请输入邮箱地址", type: "error", duration: 5 * 1000 });
    if (this.addForm.company_addr == "") return Message({ message: "请输入公司地址", type: "error", duration: 5 * 1000 });

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    await setUnion(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏对话框
    this.dialogVisible = false;

    //获取数据
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "创建成功",
      type: "success",
      duration: 2000,
    });
  }

  //处理更新
  private async updateData() {
    //判断处理
    if (this.addForm.name == "") return Message({ message: "请输入公会名称！", type: "error", duration: 5 * 1000 });
    if (this.addForm.leader_name == "") return Message({ message: "请输入负责人昵称！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.leader_id_str) == 0) return Message({ message: "请输入负责人ID！", type: "error", duration: 5 * 1000 });
    if (this.addForm.leader_credential == "") return Message({ message: "请添加负责人证件", type: "error", duration: 5 * 1000 });
    if (this.addForm.company_ssm == "") return Message({ message: "请添加公司ssm", type: "error", duration: 5 * 1000 });
    if (this.addForm.phone == "") return Message({ message: "请输入手机号", type: "error", duration: 5 * 1000 });
    if (this.addForm.email == "") return Message({ message: "请输入邮箱地址", type: "error", duration: 5 * 1000 });
    if (this.addForm.company_addr == "") return Message({ message: "请输入公司地址", type: "error", duration: 5 * 1000 });

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    await setUnion(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏对话框
    this.dialogVisible = false;

    //获取数据
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "更新成功",
      type: "success",
      duration: 2000,
    });
  }

  //----------------------------------------- 图片处理 -----------------------------------------
  //定义变量
  private ssmFileList: any[] = [];
  private creFileList: any[] = [];
  private getHeads = getHeaders();
  private getAction = this.getApi();

  //获取API
  private getApi(): string {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //图片上传
  private handlePicBeforeUploadCommon(file: any) {
    //数据赋值
    const index = file.name.lastIndexOf(".");
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];
    const isLt2M = file.size / 1024 / 1024 < 5;

    //判断结果
    if (!isLt2M) {
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出5M",
        type: "warning",
        duration: 2000,
      });
      return false;
    } else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //图片预览
  private handlePicPreviewCommon(file: any): void {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示界面
    this.dialogImageVisible = true;
  }

  //移除图片
  private handlePicRemove(file: any, fileList: any): void {
    //清空数据
    this.ssmFileList = [];
    this.addForm.company_ssm = "";
  }

  //移除图片
  private handleCreRemove(file: any, fileList: any): void {
    //清空数据
    this.creFileList = [];
    this.addForm.leader_credential = "";
  }

  //图片上传成功
  private handlePicUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.ssmFileList = fileList;
    this.addForm.company_ssm = response.data.images[0];
  }

  //图片上传成功
  private handleCreUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.creFileList = fileList;
    this.addForm.leader_credential = response.data.images[0];
  }

  //----------------------------------------- 图片处理 -----------------------------------------
  //定义变量
  private dialogImageUrl: any = null;
  private dialogImageVisible: boolean = false;
}
